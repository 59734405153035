.campaign-banner {
  background-color: #061c3f;
  color: #fff;
  height: 3.57em;
}
.campaign-banner .campaign-banner-container {
  height: 3.57em;
}
.campaign-banner .campaign-banner-message {
  margin-left: 2em;
  margin-right: 1em;
  padding-top: 0.25em;
  padding-bottom: 1em;
  font-family: "Helvetica Neue";
  line-height: 1.75em;
}
.campaign-banner .campaign-banner-message h1,
.campaign-banner .campaign-banner-message h2,
.campaign-banner .campaign-banner-message h3,
.campaign-banner .campaign-banner-message h4,
.campaign-banner .campaign-banner-message h5,
.campaign-banner .campaign-banner-message p {
  font-size: 1em;
  line-height: 1.5em;
}
.campaign-banner .campaign-banner-message a:visited {
  color: #fff;
}
.campaign-banner .campaign-banner-message a {
  color: #fff;
  margin-left: 1em;
}
.campaign-banner .campaign-banner-message p {
  margin-bottom: 0;
}
.campaign-banner .close-button {
  margin-right: 1em;
}
.campaign-banner .close-button .close {
  opacity: 1;
  width: 100%;
  height: 1.75em;
}
.campaign-banner .close-button .close img {
  width: 0.7em;
}